var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data.nombre)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.selected}},[_c('v-img',_vm._g({attrs:{"src":_vm.data.tipo == 'imagen' && _vm.data.src
              ? _vm.data.src.startsWith('blob:')
                ? `${_vm.data.src}`
                : `data:image/png;base64,${_vm.data.src}`
              : `/assets/img/multimedia.png`,"lazy-src":`/assets/img/multimedia.png`,"aspect-ratio":"1"},on:{"click":_vm.selected},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},on)),_c('div',{on:{"mouseover":function($event){_vm.delOption = true},"mouseleave":_vm.mouseleave}},[_c('v-card-title',{staticClass:"subtitle-2 text-truncate",attrs:{"title":_vm.data.nombre}},[_c('v-icon',{staticClass:"a_link",attrs:{"color":"blue lighten-1"}},[_vm._v(" "+_vm._s(_vm.icon(_vm.data.tipo))+" ")]),_vm._v(" "+_vm._s(_vm.data.nombre.substring(0, _vm.max))+_vm._s(_vm.data.nombre.length > _vm.max ? "..." : "")+" ")],1),_c('v-card-subtitle',{staticClass:"pt-2"},[_vm._v(_vm._s(_vm.data.fechaHora))]),_c('v-card-subtitle',{staticClass:"caption text-left ma-0 pb-2 pt-1",attrs:{"title":"Tipo de imagen/video/documento"}},[_vm._v(_vm._s(_vm._f("subString")(_vm.data.tipoDocumento))+" ")])],1)],1)]}}],null,false,83290893)},[_c('span',[_vm._v(" "+_vm._s(_vm.data.nombre)+" ")]),(_vm.data.tipoDocumento)?_c('span',[_c('br'),_vm._v(_vm._s(_vm.data.tipoDocumento)+" ")]):_vm._e()]),(_vm.delOption)?_c('v-btn',{staticStyle:{"margin-left":"30%","margin-top":"-10px","position":"relative"},attrs:{"small":"","color":"red","dark":"","absolute":"","fab":"","title":"Eliminar"},on:{"mousemove":function($event){_vm.overit = true},"mouseover":function($event){_vm.overit = true},"mouseleave":function($event){_vm.overit = false},"click":function($event){_vm.$emit('delete-it', _vm.data.identificador);
      _vm.overit = false;
      _vm.mouseleave();}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }