<template>
  <div v-if="data && data.nombre">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-card style="cursor: pointer" @click="selected">
          <v-img
            @click="selected"
            v-on="on"
            :src="
              data.tipo == 'imagen' && data.src
                ? data.src.startsWith('blob:')
                  ? `${data.src}`
                  : `data:image/png;base64,${data.src}`
                : `/assets/img/multimedia.png`
            "
            :lazy-src="`/assets/img/multimedia.png`"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div @mouseover="delOption = true" @mouseleave="mouseleave">
            <v-card-title class="subtitle-2 text-truncate" :title="data.nombre">
                <v-icon color="blue lighten-1" class="a_link">
                  {{ icon(data.tipo) }}
                </v-icon>

              {{ data.nombre.substring(0, max) }}{{ data.nombre.length > max ? "..." : "" }}


            </v-card-title>
            <v-card-subtitle class="pt-2">{{ data.fechaHora }}</v-card-subtitle>
            <v-card-subtitle
              class="caption text-left ma-0 pb-2 pt-1"
              title="Tipo de imagen/video/documento"
              >{{
                data.tipoDocumento | subString

              }}
            </v-card-subtitle>
          </div>
        </v-card>
      </template>
      <span> {{ data.nombre }} </span>
      <span v-if="data.tipoDocumento"><br />{{ data.tipoDocumento }} </span>
    </v-tooltip>
    <v-btn
      v-if="delOption"
      small
      color="red"
      dark
      absolute
      fab
      style= "margin-left:30%; margin-top:-10px; position:relative"
      title="Eliminar"
      @mousemove="overit = true"
      @mouseover="overit = true"
      @mouseleave="overit = false"
      @click="
        $emit('delete-it', data.identificador);
        overit = false;
        mouseleave();
      "
    >
      <v-icon>mdi-trash-can-outline</v-icon>
    </v-btn>

  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object },
    index: { type: Number },
  },
  data: () => ({
    max: 10,
    delOption: false,
    overit: false,
  }),
  filters: {
    subString(value) {
        if(value && value.length > 0){
            return value.substring(0,20) + '...';
        }
        return " - ";
    }
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    icon: function (type) {
      if (type == "imagen") return "mdi-image";
      else if (type == "documento") return "mdi-pdf-box";
      else return "mdi-play-box";
    },
    selected() {
      this.$emit("selected", this.data.identificador);
    },
    mouseleave() {
      setTimeout(() => {
        if (!this.overit) this.delOption = false;
      }, 3000);
    }
  }

};
</script>
<style scoped>
    .one-line {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
</style>